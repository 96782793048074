import { device } from 'shared/theme';
import styled from 'styled-components';

type JustifySelf = 'center' | 'start' | 'end' | 'flex-end' | 'flex-start';

interface GridColumnProps {
  span: number;
  tabletSpan?: number;
  desktopSpan?: number;
  justifySelf?: JustifySelf;
  tabletJustifySelf?: JustifySelf;
  desktopJustifySelf?: JustifySelf;
}

export const GridColumn = styled.div<GridColumnProps>`
  grid-column: ${({ span }) => `span ${span} / span ${span}`};
  justify-self: ${({ justifySelf }) => justifySelf || 'unset'};

  ${`@media ${device.md}`} {
    ${({ tabletSpan }) =>
      tabletSpan && `grid-column: span ${tabletSpan} / span ${tabletSpan}`};
    ${({ tabletJustifySelf }) =>
      tabletJustifySelf && `justify-self: ${tabletJustifySelf}`};
  }

  ${`@media ${device.lg}`} {
    ${({ desktopSpan }) =>
      desktopSpan && `grid-column: span ${desktopSpan} / span ${desktopSpan}`};
    ${({ desktopJustifySelf }) =>
      desktopJustifySelf && `justify-self: ${desktopJustifySelf}`};
  }
`;
