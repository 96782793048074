import { Base, BaseProps } from 'common/layouts/Base';
import { device } from 'shared/theme';
import styled from 'styled-components';

interface GridProps extends BaseProps {
  cols: number;
  gap?: number;
  colGap?: number;
  tabletGap?: number;
  desktopGap?: number;
  tabletCols?: number;
  desktopCols?: number;
}

export const Grid = styled(Base)<GridProps>`
  display: grid;
  grid-template-columns: ${({ cols }) => `repeat(${cols}, minmax(0, 1fr))`};
  gap: ${({ gap = 0 }) => `${gap}rem`};
  ${({ colGap }) => colGap && `column-gap: ${colGap}rem`};

  ${`@media ${device.md}`} {
    ${({ tabletCols }) =>
      tabletCols &&
      `grid-template-columns: repeat(${tabletCols}, minmax(0, 1fr))`}

    ${({ tabletGap }) => tabletGap && `gap: ${tabletGap}rem`}
  }

  ${`@media ${device.lg}`} {
    ${({ desktopCols }) =>
      desktopCols &&
      `grid-template-columns: repeat(${desktopCols}, minmax(0, 1fr))`}
    ${({ desktopGap }) => desktopGap && `gap: ${desktopGap}rem`}
  }
`;
