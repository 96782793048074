import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import Layout from 'common/layouts/Layout';
import { InlineLink } from 'common/links/InlineLink';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import {
    ExpandableSection,
    ExpandableSectionBody,
    ExpandableSectionHeading,
} from 'components/pages/legal';
import {
    H1,
    H2,
    H3,
    LegalSection,
    LegalWrapper,
    Li,
    Paragraph,
    Ul,
    Table,
    Td,
    Th
} from 'components/pages/legal/shared';
import { SEO } from 'components/SEO';
import React, { FC } from 'react';

interface PrivacyPolicyProps { }

const PrivacyPolicy: FC<PrivacyPolicyProps> = () => (
    <Layout>
        <Navigation />
        <PageWrapper>
            <LegalWrapper>
                <LegalSection>
                    <H1>
                        Privacy Policy of <strong>Retirable, Inc.</strong>
                    </H1>
                    <Paragraph>
                        <strong>Last Updated:</strong> July 31, 2024
                    </Paragraph>
                    <Paragraph marginTop={20}>
                        This Privacy Policy outlines how Retirable, Inc. (“Retirable,” the “Company,” “we,” “our,” or “us”) processes the information we collect about you through our website, https://retirable.com (the “Website”), through our Retirable mobile application, and through our other online services (collectively, the “Services”), and when you otherwise interact with us, such as through our customer service channels.
                    </Paragraph>
                    <Paragraph marginTop={20}>
                        By accessing or using any part of the Services, you agree that you have read, understood, and agree to be bound by this Privacy Policy. If you do not agree to any of the provisions as set forth in this Privacy Policy, you have no right to use the Services.
                    </Paragraph>
                </LegalSection>
                <LegalSection>
                    <H1>
                        Retirable U.S. Consumer Privacy Notice
                    </H1>
                    <Table>
                        <thead>
                            <tr>
                                <Th>FACTS</Th>
                                <Th>WHAT DOES RETIRABLE DO WITH YOUR PERSONAL INFORMATION?</Th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Th>Why?</Th>
                                <Td>Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.</Td>
                            </tr>
                            <tr>
                                <Th>Why?</Th>
                                <Td>
                                    <Paragraph>The types of personal information we collect and share depend on the product or service you have with us. This information can include:</Paragraph>
                                    <Ul>
                                        <Li>Your full name, email address and phone number</Li>
                                        <Li>Your Social Security number and date of birth</Li>
                                        <Li>Your physical address and zip code</Li>
                                        <Li>Your income, expenses, account balances, transactions, and transaction history</Li>
                                        <Li>Identification documents, such as a Driver's License or Passport</Li>
                                        <Li>Device information, including your IP address, MAC address, and device ID</Li>
                                    </Ul>
                                </Td>
                            </tr>
                            <tr>
                                <Th>How?</Th>
                                <Td>All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reason Retirable chooses to share; and whether you can limit this sharing.</Td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <thead>
                            <tr>
                                <Th>Reasons we can share your personal information</Th>
                                <Th>Does Retirable share?</Th>
                                <Th>Can you limit this sharing?</Th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Td>
                                    <strong>For our everyday business purposes</strong> &mdash;
                                    such as to process your transactions, maintain your account(s), respond to court orders and legal investigations
                                </Td>
                                <Td>Yes</Td>
                                <Td>No</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For our marketing purposes</strong> &mdash;
                                    to offer our products and services to you
                                </Td>
                                <Td>Yes</Td>
                                <Td>No</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For our marketing purposes</strong> &mdash;
                                    to offer our products and services to you
                                </Td>
                                <Td>No</Td>
                                <Td>We don&apos;t share</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For joint marketing with other financial companies</strong> &mdash;
                                </Td>
                                <Td>No</Td>
                                <Td>We don&apos;t share</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For our affiliates&apos; everyday business purposes </strong> &mdash;
                                    information about your transactions and experiences
                                </Td>
                                <Td>No</Td>
                                <Td>We don&apos;t share</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For our affiliates’ everyday business purposes </strong> &mdash;
                                    information about your creditworthiness
                                </Td>
                                <Td>No</Td>
                                <Td>We don&apos;t share</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For our affiliates to market to you</strong>
                                </Td>
                                <Td>No</Td>
                                <Td>We don&apos;t share</Td>
                            </tr>
                            <tr>
                                <Td>
                                    <strong>For nonaffiliates to market to you</strong>
                                </Td>
                                <Td>No</Td>
                                <Td>We don&apos;t share</Td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <tbody>
                            <tr>
                                <Th>To limit our sharing</Th>
                                <Td>
                                    While we do not presently share personal information about you that you have the ability to limit, this Privacy Policy may be amended from time to time and our sharing practices may change. If we begin to share personal information about you in ways that you may limit, we will provide you with information on how to exercise your limitation rights.
                                </Td>
                            </tr>
                            <tr>
                                <Th>Questions?</Th>
                                <Td>Email us at privacy@retirable.com</Td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <tbody>
                            <tr>
                                <Th>Who are we?</Th>
                                <Td></Td>
                            </tr>
                            <tr>
                                <Td>Who is providing this notice?</Td>
                                <Td>Retirable, Inc.</Td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <thead>
                            <tr>
                                <Th>What we do</Th>
                                <Td></Td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Td><strong>How does Retirable protect my personal information?</strong></Td>
                                <Td>To protect your personal information from unauthorized access and use, we use reasonable security measures that comply with industry standards. These measures include computer safeguards and secured files and buildings.</Td>
                            </tr>
                            <tr>
                                <Td><strong>How does Retirable collect my personal information?</strong></Td>
                                <Td>We collect your personal information, for example, when you:
                                    <Ul>
                                        <Li>Give us your contact information</Li>
                                        <Li>Use the Retirable Services, such as talking with one of our Financial Advisors</Li>
                                        <Li>Open an account, or otherwise provide information to open an account with us</Li>
                                        <Li>Are referred to our Services by another company</Li>
                                    </Ul>
                                </Td>
                            </tr>
                            <tr>
                                <Td><strong>Why can&apos;t I limit all sharing?</strong></Td>
                                <Td>Federal law gives you the right to limit only:
                                    <Ul>
                                        <Li>affiliates from using your information to market to you</Li>
                                        <Li>sharing for nonaffiliates to market to you</Li>
                                        <Li>for joint marketing with other financial companies</Li>
                                    </Ul>
                                    State laws and individual companies may give you additional rights to limit sharing. See below for more on your rights under state law.
                                </Td>
                            </tr>
                            <tr>
                                <Td><strong>What happens when I limit sharing for an account I hold jointly with someone else?</strong></Td>
                                <Td>Your choices will apply to everyone on your account.</Td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <thead>
                            <tr>
                                <Th>Definitions</Th>
                                <Td></Td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Td><strong>Affiliates</strong></Td>
                                <Td>Companies related by common ownership or control. They can be financial and nonfinancial companies.</Td>
                            </tr>
                            <tr>
                                <Td><strong>Nonaffiliates</strong></Td>
                                <Td>
                                    Companies not related by common ownership or control. They can be financial and nonfinancial companies. Nonaffiliates with whom we may share can include:
                                    <Ul>
                                        <Li>other financial services companies (including other financial institutions, such as banks)</Li>
                                        <Li>non-financial service providers such as hosting platforms, loyalty programs, KYC/AML and fraud protection vendors, sanctions screening vendors, data processors, marketing partners, and API integrators</Li>
                                        <Li>companies that refer clients to Retirable</Li>
                                    </Ul>
                                </Td>
                            </tr>
                            <tr>
                                <Td><strong>Joint marketing</strong></Td>
                                <Td>
                                    A formal agreement between nonaffiliated financial companies that together market financial products or services to you.
                                    <Ul>
                                        <Li>
                                            Other companies may market Retirable's Services to you.
                                        </Li>
                                        <Li>
                                            If you opt-in to be referred to Retirable, your contact information may be shared with us. In some cases, the other company will receive compensation for making the introduction, and/or if you enter into a paying relationship with Retirable. You will not be charged any additional fees or incur any additional costs based on this referral.
                                        </Li>
                                    </Ul>
                                </Td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <thead>
                            <tr>
                                <Th>Other important information</Th>
                                <Td></Td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Td><strong>Affiliates</strong></Td>
                                <Td>For California and Vermont residents: We will not share information we collect about you with affiliates or non-affiliates, except as permitted by your state’s law.                                </Td>
                            </tr>
                        </tbody>
                    </Table>
                </LegalSection>
                <LegalSection>
                    <H1>Online Privacy Policy</H1>
                    <Paragraph marginBottom={20}>
                        This Online Privacy Policy applies to all online and offline interactions with us, as more specifically noted below, and our practices relating to the personal information we collect from you.
                    </Paragraph>

                    <H2 medium marginBottom={20}>Types of Personal Information We Collect and How We Collect It:</H2>
                    <H3 medium>How Do We Collect Personal Information Online?</H3>
                    <Paragraph>We may collect the following personal information you provide when you use our Services, like when you:</Paragraph>
                    <Ul>
                        <Li>Interact with a Retirable financial advisor</Li>
                        <Li>Apply for or open a new account</Li>
                        <Li>Request information about a product or service</Li>
                        <Li>Engage or communicate with us</Li>
                    </Ul>


                    <H3 medium>Personal Information That You Provide</H3>
                    <Paragraph>The type of personal information we collect from and about you will depend on how you interact with us and may include:</Paragraph>
                    <Ul>
                        <Li><strong>Contact Information</strong> such as name, mailing address, email address, phone number.</Li>
                        <Li><strong>Financial Planning Information</strong> such as household size, retirement preferences, lifestyle goals, income, expenses, account balances, pensions, government program benefit amounts, insurance policies, pension information, housing situation, marital status. We use this information to construct a financial plan, and to facilitate the creation of your account.</Li>
                        <Li><strong>Identity Data</strong> such as your social security number, driver’s license number or other information that identifies you for ordinary business purposes. We do not store your SSN on our systems. Instead, this data is transmitted through our server to create banking and investment accounts for you.</Li>
                        <Li><strong>Identifiers</strong> such as Social Security number, account number(s), driver's license number (or comparable) or other information that identifies you for ordinary business purposes.</Li>
                        <Li><strong>Documents or Images</strong> submitted to support account opening, such as driver’s license, bank statements and voided checks.</Li>
                        <Li><strong>Additional Information</strong> we also collect any communications between Retirable and you, including through recording phone and video calls made to or by Retirable, customer support inquiries, blog posts, and any other interactions with us.</Li>
                    </Ul>

                    <H3 medium>Personal Information Collected Automatically</H3>
                    <Paragraph marginBottom={20}>When you visit our website or use our Services, we automatically collect certain information about you, like your IP address, MAC address, Device ID, and details about how you use our mobile app, including the content you view, your interactions, and how long you spend in the app.</Paragraph>
                    <Paragraph marginBottom={20}>We use this information to analyze and improve our Services and content. Our servers may also keep log files that record data each time a device accesses our website or services. These log files can include your IP address, operating system, browser type and version, and the website or page that referred you to our site or services.</Paragraph>
                    <Paragraph marginBottom={20}>We use cookies and similar technologies, like pixel tags, to automatically collect this information and track your use of our website and services. These technologies gather data about your experience on our website, such as the pages you click on, your navigation path, bounce rates, actions taken, and other metrics.</Paragraph>

                    <Paragraph marginBottom={20}>
                        <strong>What Are Cookies? </strong>
                        Cookies are small files stored in your browser that collect data about your visit to our website or services. They help maintain your session and preferences securely (e.g., remembering your login details or settings), understand how you use the website or services, and serve ads based on your interests or actions. Each cookie usually has a unique ID and may store information related to your device or browser, like your IP address, browser and operating system type, and device type.
                    </Paragraph>

                    <Paragraph marginBottom={20}>
                        <strong>What Are Pixel Tags? </strong>
                        Pixel tags are small pieces of JavaScript in a webpage or email that (a) store cookies and (b) track when a page or email has been viewed. On mobile devices, a similar function is served by an SDK (software development kit). For simplicity, we refer to both as pixel tags. Pixel tags create cookies in your browser and recognize them when you revisit a site, reading the stored information and communicating it to the cookie&apos;s owner. They also send data when user-initiated events occur, like clicking an ad or button on the website, or a link in an email.
                    </Paragraph>

                    <Paragraph marginBottom={20}>We also use third-party analytics tools to help us understand how users interact with our services, which helps us tailor our Services to your needs. We do not control the privacy practices of these third parties and cannot be held responsible for them (e.g., if they don&apos;t comply with opt-out requests). The personal information we collect is limited to what is required to provide our products or services and to support legal and risk requirements.</Paragraph>

                    <Paragraph marginBottom={20}>
                        Personal Data is collected for the following purposes and using the following third-party services:
                    </Paragraph>
                    <Grid cols={2} gap={1}>
                        <GridColumn span={2} desktopSpan={1}>
                            <ExpandableSection>
                                <ExpandableSectionHeading>Analytics</ExpandableSectionHeading>
                                <ExpandableSectionBody>
                                    <Paragraph marginBottom={20}>
                                        The services contained in this section enable us to
                                        monitor and analyze web traffic and can be used to keep
                                        track of user behavior.
                                    </Paragraph>
                                    <Paragraph medium marginBottom={20}>
                                        Google Analytics (Google LLC)
                                    </Paragraph>
                                    <Paragraph>
                                        Google Analytics is a web analysis service provided by
                                        Google LLC (“Google”).
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Google may use the Data collected to contextualize and
                                        personalize the ads of its own advertising network.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Personal Data processed: Cookies; Usage Data.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Place of processing: United States —{' '}
                                        <InlineLink href="https://policies.google.com/privacy">
                                            Privacy Policy
                                        </InlineLink>{' '}
                                        —{' '}
                                        <InlineLink href="https://tools.google.com/dlpage/gaoptout?hl=en">
                                            Opt Out.
                                        </InlineLink>
                                    </Paragraph>
                                    <Paragraph medium marginBottom={20}>
                                        Amplitude Analytics (Amplitude Inc.)
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Amplitude Analytics is an analytics service provided by
                                        Amplitude Inc.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Personal Data processed: Cookies; Usage Data.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Place of processing: United States —{' '}
                                        <InlineLink href="https://amplitude.com/privacy">
                                            Privacy Policy
                                        </InlineLink>
                                    </Paragraph>
                                    <Paragraph medium marginBottom={20}>
                                        FullStory (FullStory, Inc.)
                                    </Paragraph>
                                    <Paragraph>
                                        FullStory is an analytics and heat mapping service provided
                                        by FullStory, Inc.
                                    </Paragraph>
                                    <Paragraph>
                                        FullStory maps how users interact with the
                                        different pages and applications of Retirable, Inc, allowing us to improve the user experience.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        FullStory further allows for the recording of mapping
                                        sessions making them available for later visual playback.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Personal Data processed: Tracker; Usage Data.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Place of processing: United States —{' '}
                                        <InlineLink href="https://www.fullstory.com/legal/privacy/">
                                            Privacy Policy
                                        </InlineLink>{' '}
                                        —{' '}
                                        <InlineLink href="https://www.fullstory.com/legal/privacy/">
                                            Opt out
                                        </InlineLink>
                                    </Paragraph>
                                </ExpandableSectionBody>
                            </ExpandableSection>
                        </GridColumn>
                        <GridColumn span={2} desktopSpan={1}>
                            <ExpandableSection>
                                <ExpandableSectionHeading>
                                    Contacting the User
                                </ExpandableSectionHeading>
                                <ExpandableSectionBody>
                                    <Paragraph medium marginBottom={20}>
                                        Mailing list or newsletter (Retirable, Inc.)
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        By registering on the mailing list or for the newsletter,
                                        the user&apos;s email address will be added to the contact
                                        list of those who may receive email messages containing
                                        information of commercial or promotional nature concerning
                                        Retirable, Inc. Your email address might also be added to
                                        this list as a result of signing up to Retirable, Inc. or
                                        after making a purchase.
                                    </Paragraph>
                                    <Paragraph>Personal Data processed: email address.</Paragraph>
                                </ExpandableSectionBody>
                            </ExpandableSection>
                        </GridColumn>
                        <GridColumn span={2} desktopSpan={1}>
                            <ExpandableSection>
                                <ExpandableSectionHeading>
                                    Displaying content from external platforms
                                </ExpandableSectionHeading>
                                <ExpandableSectionBody>
                                    <Paragraph>
                                        This type of service allows you to view content hosted on
                                        external platforms directly from the pages of Retirable,
                                        Inc. and interact with them.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        This type of service might still collect web traffic data
                                        for the pages where the service is installed, even when
                                        Users do not use it.
                                    </Paragraph>
                                    <Paragraph marginBottom={20} medium>
                                        Google Fonts (Google LLC)
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Google Fonts is a typeface visualization service provided by
                                        Google LLC that allows Retirable, Inc. to incorporate
                                        content of this kind on its pages.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Personal Data processed: Usage Data; various types of Data
                                        as specified in the privacy policy of the service.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Place of processing: United States —{' '}
                                        <InlineLink href="https://policies.google.com/privacy">
                                            Privacy Policy
                                        </InlineLink>
                                    </Paragraph>
                                </ExpandableSectionBody>
                            </ExpandableSection>
                        </GridColumn>
                        <GridColumn span={2} desktopSpan={1}>
                            <ExpandableSection>
                                <ExpandableSectionHeading>
                                    Hosting and backend infrastructure
                                </ExpandableSectionHeading>
                                <ExpandableSectionBody>
                                    <Paragraph marginBottom={20}>
                                        This type of service has the purpose of hosting Data and
                                        files that enable Retirable, Inc. to run and be distributed
                                        as well as to provide a ready-made infrastructure to run
                                        specific features or parts of Retirable, Inc.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Some services among those listed below, if any, may work
                                        through geographically distributed servers, making it
                                        difficult to determine the actual location where the
                                        Personal Data are stored.
                                    </Paragraph>
                                    <Paragraph medium marginBottom={20}>
                                        Algolia
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Algolia provides search functionality for our website content
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Place of processing: USA —{' '}
                                        <InlineLink href="https://www.algolia.com/policies/privacy/">
                                            Privacy Policy
                                        </InlineLink>
                                    </Paragraph>
                                    <Paragraph medium marginBottom={20}>
                                        Contentful
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Contentful is a content hosting and backend service
                                    </Paragraph>
                                    <Paragraph>
                                        Place of processing: USA —{' '}
                                        <InlineLink href="https://www.contentful.com/privacy/">
                                            Privacy Policy
                                        </InlineLink>
                                    </Paragraph>
                                </ExpandableSectionBody>
                            </ExpandableSection>
                        </GridColumn>
                        <GridColumn span={2} desktopSpan={1}>
                            <ExpandableSection>
                                <ExpandableSectionHeading>
                                    Tag Management
                                </ExpandableSectionHeading>
                                <ExpandableSectionBody>
                                    <Paragraph>
                                        This type of service helps to manage the tags or
                                        scripts needed on Retirable, Inc. in a centralized fashion.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        This results in the Users&apos; Data flowing through these
                                        services, potentially resulting in the retention of this
                                        Data.
                                    </Paragraph>
                                    <Paragraph medium marginBottom={20}>
                                        Segment (Segment Inc.)
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Segment is a tag management service provided by Segment.io,
                                        Inc.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Personal Data processed: Cookies; Usage Data.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Place of processing: United States —{' '}
                                        <InlineLink href="https://segment.com/docs/legal/privacy/">
                                            Privacy Policy
                                        </InlineLink>
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Google Tag Manager (Google LLC)
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Google Tag Manager is a tag management service provided by
                                        Google LLC.
                                    </Paragraph>
                                    <Paragraph marginBottom={20}>
                                        Personal Data processed: Usage Data.
                                    </Paragraph>
                                    <Paragraph>
                                        Place of processing: United States —{' '}
                                        <InlineLink href="https://policies.google.com/privacy">
                                            Privacy Policy
                                        </InlineLink>
                                    </Paragraph>
                                </ExpandableSectionBody>
                            </ExpandableSection>
                        </GridColumn>
                    </Grid>

                    <H3 medium marginTop={20}>Your Choices Regarding Tracking Technologies</H3>

                    <Paragraph marginBottom={20}>
                        Most internet browsers automatically accept cookies and pixel tags. You can change your browser settings to notify you when a cookie is set, allowing you to decide whether to accept it. You can also block all third-party cookies or all cookies in general. If you choose not to accept any cookies or similar technologies, you may not be able to use all features of our website or Services. If you switch devices or browsers, you may need to adjust these settings again. Retirable does not track our customers over time or across third-party websites for targeted advertising, and therefore does not respond to Do Not Track (DNT) signals.
                    </Paragraph>

                    <Paragraph marginBottom={20}>
                        On your mobile device, you can limit certain interest-based ads through your device settings, like resetting your ID for Advertising (IDFA) on iOS, Google Advertising ID, and similar identifiers. Check your device manufacturer or operating system for specific instructions.
                    </Paragraph>

                    <H2>How We Use Your Personal Information</H2>
                    <Paragraph>We use your information in several ways, including:</Paragraph>

                    <Ul>
                        <Li>Managing your preferences</Li>
                        <Li>Verifying your identity when you access your account</Li>
                        <Li>Responding to your requests and communicating with you</Li>
                        <Li>Providing and customizing our services to fit your location and interests</Li>
                        <Li>Preventing fraud and enhancing the security of our Service and your account(s)</Li>
                        <Li>Complying with and enforcing legal requirements, industry standards, contracts, and our policies</Li>
                        <Li>Analyzing how you use our services, including your responses to our emails and the pages or app content you view</Li>
                        <Li>Monitoring and fixing errors on our website and services, analyzing web traffic, and optimizing user experience</Li>
                        <Li>Operating, evaluating, and improving our business, developing new products and services, performing data analytics, and handling accounting, auditing, and other internal functions</Li>
                        <Li>For any other purpose that we may specifically disclose at the time that you provide or we collect your information.</Li>
                    </Ul>
                    <Paragraph marginBottom={20}>
                        We may also use aggregated or anonymous data (that doesn’t identify you) for various business purposes, where permissible under applicable laws and regulations.
                    </Paragraph>
                    <H3 medium>How We Share Your Personal Information</H3>
                    <Paragraph>We may share some or all of the personal information that you provide to one or more of our service providers, professional agencies, vendors, law enforcement, card networks, payment processors and others as necessary for:</Paragraph>
                    <Ul>
                        <Li>Providing our Services to you, such as by transmitting your personal information to a financial institution to open a new account;</Li>
                        <Li>Providing our customer support functions;</Li>
                        <Li>Assisting in delivery of physical and electronic communications to you;</Li>
                        <Li>Other business purposes, such as the administration of our Website and our other Services, compliance with audits, for tax purposes, as part of, or during negotiations of, a merger, consolidation, sale of our assets, as well as equity financing, acquisition, strategic alliance or in any other situation where personal information may be transferred as one of the business assets of Retirable;</Li>
                        <Li>Responding to requests by law enforcement, regulators, and other government bodies; and,</Li>
                        <Li>Any other purpose as permitted by law.</Li>
                    </Ul>
                    <Paragraph marginBottom={20}>
                        Retirable does not sell your personal information to any third party, but we may provide certain of your personal information to third parties to facilitate account creation, identity and benefits verification, payment of referral fees, or similar purposes.
                    </Paragraph>

                    <H2>Marketing Communications</H2>
                    <Paragraph marginBottom={20}>Retirable may occasionally contact you with information about new products or services. You can manage your marketing emails by clicking the “Unsubscribe” link in any Retirable marketing email and following the instructions.</Paragraph>
                    <Paragraph marginBottom={20}>Please note, that you cannot opt-out of transactional or administrative emails.</Paragraph>

                    <H2>Protecting And Storing Your Personal Information</H2>
                    <Paragraph marginBottom={20}>To keep your personal information safe from unauthorized access and use, we follow strict security measures that comply with federal and state laws. These include protecting our devices, securing our files and buildings, and monitoring our third-party providers to ensure your information stays confidential and secure. If a data breach occurs, we will notify you promptly as required by law. While we work hard to protect your information, no data storage system or internet transmission can be guaranteed to be completely secure.</Paragraph>
                    <H3 medium>Data Retention</H3>
                    <Paragraph marginBottom={20}>We keep your personal information as long as it is necessary and relevant for our operations, unless a longer retention period is required or allowed by law.</Paragraph>
                    <Paragraph marginBottom={20}>To close your Retirable accounts and remove your personal information contact privacy@retirable.com. We may need to confirm your identity and account ownership to process your request.</Paragraph>
                    <H3 medium>Legal Action</H3>
                    <Paragraph marginBottom={20}>We may use your personal data for legal purposes, such as in court or during legal actions related to misuse of Retirable, Inc. or its services. By using our Services and/or by providing personal data to us, you acknowledge that we may be required to share your personal data if requested by public authorities.</Paragraph>

                    <H2>International Users</H2>
                    <Paragraph marginBottom={20}>Our services are hosted in the United States and follow U.S. laws. If you are outside the U.S. and submit personal information, you consent to the use and transfer of your information to the U.S., where the laws may not offer the same level of protection as your country.</Paragraph>

                    <H2>Children&apos;s Privacy</H2>
                    <Paragraph marginBottom={20}>Our Services are not intended for use by children and we do not knowingly collect information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete it. If you believe we might have information from a child under 13, please contact us at privacy@retirable.com.</Paragraph>

                    <H2>Changes to this Privacy Policy</H2>
                    <Paragraph marginBottom={20}>This notice may change over time. We update it periodically to comply with the latest federal and local laws. Please review it if you have any questions. If we make changes to this notice, we will update the “Last updated” date on this page.</Paragraph>

                    <H2>Questions?</H2>
                    <Paragraph marginBottom={20}>If you have any questions or concerns about this statement, or if you have any suggestions, please contact us at privacy@retirable.com.</Paragraph>
                </LegalSection>
            </LegalWrapper>
        </PageWrapper>
    </Layout>
);

export default PrivacyPolicy;

export const Head = () => (
    <SEO
        title="Privacy Policy — Retirable"
        description="View the privacy policy for Retirable."
    />
);
